@import '../../styles/customMediaQueries.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  flex-grow: 1;
}

.contentContainer {
  composes: marketplaceModalRootStyles from global;
}

.content {
  composes: marketplaceModalBaseStyles from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}
