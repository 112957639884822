.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

& :global(#tos) {
  & .text {
    & p {
      font-size: 14px;
      margin: 15px 0 0;
      padding: 0;
      text-align: justify;
      color: var(--hcbrandBlack);
      font-weight: normal;
    }
  }
}
