.removeImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--colorGrey300);
    stroke: var(--colorGrey300);
  }

  &:hover svg {
    fill: var(--colorWhite);
    stroke: var(--colorWhite);
  }
}
