@import '../../styles/customMediaQueries.css';

.footerWrapper {
  margin: 0 auto;
  max-width: 986px;
  width: 100%;


  @media (--viewportLarge) {
    padding: 50px 0 50px;
  }
}

.footerContent {
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.linksHolder {
  display: flex;
  width: 100%;
  flex: 0 0 100%;
  border-bottom: 1px solid #d8dce6;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 20px;

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
  }
}

.logoHolder {
  /*margin-right: 68px;*/
}

.logoLink {

}

.logoWrapper {

}

.logoImage {

}

.linksList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.list {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.listItem {
  margin: 0 9px;
}

.link {
  font-size: 14px;
  line-height: 24px;
  display: block;
}

.socialLinks {
  display: flex;
  justify-content: space-around;
  margin-left: 4px;

  @media (--viewportLarge) {
    margin-left: 30px;
  }
}

.detailsInfo {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
  }
}

.icon {
  display: block;
  width: 20px;
  margin: 0 4px;
}

.securePaymentTxt {
  font-size: 12px;
  line-height: 16px;
  margin: 10px 0;

  @media (--viewportLarge) {
    margin: 0;
  }
}

.copy {
  font-size: 12px;
  line-height: 16px;
}

.svg {
  overflow: visible!important;
}

.cardsHolder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (--viewportLarge) {
    align-items: center;
    flex-direction: row;
  }
}

.card {
  margin-right: 5px;
}
