@import '../../styles/marketplaceDefaults.css';
@import '../../styles/customMediaQueries.css';

.label {
  margin-right: 5px;
}

.error {
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.labelWrapper {
  display: flex;
  align-items: center;
}

.phoneInputNumberContainer {
  /*margin-bottom: 20px;*/
}
