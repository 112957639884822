@import '../../styles/customMediaQueries.css';

.fileSection {
  border: 1px dashed #ccc;
  border-radius: 5px;
  display: flex;
  width: 100%;
  height: 240px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btnLabel {
  letter-spacing: -1px;
  font-size: 15px;
  display: block;
  line-height: 14px;
}

.fileTip {
  display: block;
  line-height: 14px;
  font-weight: 300;
  font-size: 12px;
}

.btn {
  max-width: 250px;
}

.fileIconWrapper {
  text-align: center;
}

.largePreview {
  display: block !important;
  max-width: 100% !important;
}

.uploadInput {
  display: none;
}

.filesContainer {
  display: flex;
  width: 100%;
  gap: 15px;
  flex-wrap: wrap;
}

.imageContainer {
  position: relative;
  max-width: 100%;

  @media (--viewportSmall) {
    max-width: calc(50% - 8px);
  }
}

.image {
  max-width: 100%;
}

.removeButton {
  position: absolute;
  top: 0;
  right: 0;
}

.removeImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  /*border-bottom-left-radius: 2px;*/
  cursor: pointer;

  & svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--colorGrey300);
    stroke: var(--colorGrey300);
  }

  &:hover svg {
    fill: var(--colorWhite);
    stroke: var(--colorWhite);
  }
}

.error {
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}
