.root {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 150px;
}

.img {
  display: block;
  width: 100%;
}
